import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/beginSurvey",
        name: "BeginSurvey",
        component: () => import("../views/BeginSurvey.vue")
    },
    {
        path: "/finishedSurvey",
        name: "FinishedSurvey",
        component: () => import("../views/FinishedSurvey.vue")
    },
    {
        path: "/calculator",
        name: "Calculator",
        component: () => import("../views/Calculator.vue")
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

export default router;
