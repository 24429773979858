<template>
  <b-container fluid class="p-0">
    <b-container fluid class="header__container">
      <b-container>
        {{ title }}
      </b-container>
    </b-container>
    <b-container fluid class="sub_header__container">
      <b-container>
        {{ subTitle }}
      </b-container>
    </b-container>
  </b-container>
</template>

<script>
export default {
  props: {
    title: String,
    subTitle: String,
  },
};
</script>

<style scoped>
.header__container {
  background-color: #fff;
  font-weight: bold;
  padding: 20px 0px;
  text-transform: uppercase;
  font-size: 28px;
}
.sub_header__container {
  background-color: #fd8a02;
  color: #fff;
  padding: 10px;
  font-weight: 500;
}
@media (max-width: 992px) {
  .header__container {
    text-align: center;
  }
  .sub_header__container {
    text-align: center;
    font-size: 12px;
  }
}
</style>
