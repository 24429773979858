<template>
    <b-container fluid class="p-0">
        <the-header :title="title" :subTitle="subTitle"></the-header>

        <transition name="fade" mode="out-in" appear>
            <router-view />
        </transition>
    </b-container>
</template>

<script>
import base from "./config/base";
import TheHeader from "./components/layout/TheHeader.vue";
export default {
    components: {
        TheHeader
    },
    data() {
        return {
            title: base.homeHeader,
            subTitle: base.homeSubHeader
        };
    }
};
</script>

<style lang="scss">
.selector-for-some-widget {
    box-sizing: content-box;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
.custom-radio .custom-control-input {
    margin-right: 10px;
}
.custom-radio {
    border-bottom: 1px solid #e2e2e2;
    padding: 10px 45px;
}
.custom-radio:last-child {
    border-bottom: 0px;
}
.col-form-label {
    text-align: center;
    font-weight: bold;
    font-size: 28px !important;
}
</style>
