let base = {
  // sitename
  siteName: "KFZ-Versicherung",
  // home
  homeHeader: "KFZ-Versicherung",
  homeSubHeader: "KFZ-Versicherung bis zu 850€ im Jahr sparen!",
  homeDescription:
    "Damit wir für Sie die passende KFT-Versicherung ermitteln können, drücken Sie auf die Taste '<b>Jetzt Starten</b>'.",
  homeButton: "Jetzt starten!",
  // server
  serverHeader: "Server wird angefragt!",
  serverMessages: [
    "Antworten",
    "Bitte warten ...",
    "Daten werden verarbeitet ...",
    "Prüfung abgeschlossen!",
  ],
  // finished
  finishedHeader: "Herzlichen Glückwunsch!",
  finishedDescription: "Sie können nun vergleichen und viel Geld einsparen!",
  finishedButton: "Los gehts!",
};

module.exports = base;
