<template>
  <b-container class="home__container text-center">
    <b-img
      class="logo__container"
      :src="require('@/assets/images/start01.jpg')"
      fluid
    ></b-img>
    <p class="home_description__container" v-html="base.homeDescription"></p>
    <b-button @click="beginSurvey" class="begin__button w-100" size="lg">{{
      base.homeButton
    }}</b-button>
  </b-container>
</template>

<script>
import base from "../config/base";
export default {
  components: {},
  data() {
    return {
      base,
    };
  },
  methods: {
    beginSurvey() {
      this.$router.push("/beginSurvey");
    },
  },
};
</script>

<style scoped>
.home__container {
  margin-top: 20px;
}
.home_description__container {
  text-align: center;
  padding: 25px 20px;
  font-size: 12px;
  background-color: #f8f8f8;
}
.logo__container {
  margin-bottom: 10px;
}
.begin__button {
  background-color: #fd8a02;
  border: 0px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 20px;
}
</style>
